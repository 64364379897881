export default [
  {
    path: 'players',
    children: [
      {
        name: 'players',
        path: '',
        component: () => import('./PlayersList.vue'),
        meta: { title: 'Players', guard: false }
      },
      {
        name: 'create-player',
        path: 'create',
        component: () => import('./PlayerForm.vue'),
        meta: { title: 'New Player' },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-player',
        path: ':id/edit',
        component: () => import('./PlayerForm.vue'),
        meta: { title: 'Edit Player' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'view-player',
        path: ':id/view',
        component: () => import('./PlayerView.vue'),
        meta: {
          title: 'View Player'
        },
        props: (route) => ({
          id: route.params.id
        })
      },
      {
        name: 'import-players',
        path: 'import',
        component: () => import('../ImportWizard.vue'),
        meta: { title: 'Import Players' },
        props: {
          type: 'players'
        }
      },
    ]
  },
]
